<template>
  <div :id="[stylecss == 'style2' ? 'header-wrapper2' : 'header-wrapper' ]" :style="[ this.$store.state.customBranding.style == 'style2' ? {'background-color' : (this.$store.state.customBranding.bgColor)} : {'background-color' : ''}]">
    <div v-if="showBack"
      class="buttonBackTo text"
      @click="backToWebpage()"
    >{{ $t("unauthenticated.back") | uppercase }}</div>
    <div :class="[stylecss == 'style2' ? 'locale-changer2' : 'locale-changer' ]">
      <custom-select v-model="$i18n.locale" :options="langs" />
    </div>
  </div>
</template>

<script>
const CustomSelect = () => import("../components/CustomSelect");

export default {
  name: "NagiHeader",
  components: {
    CustomSelect
  },
  data: function() {
    return {
      langs: ["es", "en"]
      // selectedLang: 'en'
    };
  },
  props: {
    showBack: {
      type: Boolean,
      default: false
    },
    stylecss: null,
  },
  methods: {
    backToWebpage() {
      this.$router.go(-1);
    }
  }
};
</script>

<style>
#header-wrapper {
  width: auto;
  height: fit-content;
  flex-direction: row;
  display: flex;
  color: white;
  z-index: 5;
}
#header-wrapper2{
  width: auto;
  height: fit-content;
  flex-direction: row;
  display: flex;
  color: white;
  top: 50px;
  z-index: 5;
}
.locale-changer {
  padding-top: 16px;
}
.locale-changer2 {
  padding-top: 4px;
  padding-right: 16px;
  margin: 0 1.5em 0 auto;
}
.buttonBackTo {
  z-index: 1;
  font-size: 0.875rem;
  height: fit-content;
  width: fit-content;
  align-self: flex-start;
  margin: 1.5em auto 1em 1.5em;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .buttonBackTo {
    margin-bottom: 3rem;
  }
  .locale-changer2 {
    padding-top: 0px;
    padding-right: 16px;
    margin: -3px 1.5em 0 auto;
  }
  #header-wrapper{
    width: 100%;
  }
}
</style>